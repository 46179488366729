<template>
  <div class="col-auto p-0 d-flex flex-column align-items-center">
    <JayaTitle classType="page-title" titleName="Nous contacter"/>
    <b-card class="mt-1 text-center">

      <p class="font-weight-bold">
        Vous souhaitez nous contacter pour une question, un commentaire ou une suggestion ?
      </p>
      <p>
        Ecrivez nous un email à : <strong><a href="mailto:contact@hortilio.fr">
        contact&#64;hortilio&#46;fr</a></strong>
      </p>

      <p>
        <strong>Ou</strong>
      </p>

      <p>
        Laissez-nous un message sur <strong><a @click="$openLinkJaya('https://www.facebook.com/JayaGardenWeb/')" class="text-primary">
        Facebook</a></strong>
      </p>

      <p>
        Nous ferons notre possible pour vous répondre au plus vite.
      </p>
    </b-card>
  </div>
</template>

<script>
import JayaTitle from '@/components/global/JayaTitle.vue'
import {BCard} from "bootstrap-vue"

export default {
  name: 'about-us',
  components: {
    JayaTitle,
    BCard
  }
}
</script>
